import React from "react"
import { Layout } from "@components/Layout"
import SignIn from "@auth/Signin"

import { SEO } from "@components/SEO"
import { AuthPageWrapper, SignUpWrapper } from "@auth/index.styles"
import { AuthTitle } from "@styles/Global.forms"
import ResetPassword from "@privateComponents/ResetPassword"

const ResetPasswordPage = (props: any) => {
  return (
    <Layout version="blank" authEnabled={true}>
      <SEO title="Reset Your Password" />
      <AuthPageWrapper>
        <SignUpWrapper>
          <AuthTitle>Reset Password</AuthTitle>
          <ResetPassword />
        </SignUpWrapper>
      </AuthPageWrapper>
    </Layout>
  )
}

export default ResetPasswordPage
