import React, { useState } from "react"
import theme from "@styles/theme"
import { Form, Input } from "semantic-ui-react"
import { AuthFormLabel } from "@styles/Global.forms"
import { ButtonTypes } from "@utils/Constant"
import { KpButton } from "@elements/index"
import { ErrorContainer } from "@styles/Global.styles"
import { Auth } from "aws-amplify"
import { CognitoUser } from "amazon-cognito-identity-js"
import { navigate } from "gatsby"

const ResetPassword = () => {
  const [email, setEmail] = useState("")
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const updatePassword = () => {
    setLoading(true)
    Auth.signIn(email, currentPassword)
      .then(user => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(user, newPassword)
            .then(user => {
              console.log(user)
              navigate("/app/profile")
            })
            .catch(e => {
              console.log(e)
              setError(e.message)
            })
            .finally(() => {
              setLoading(false)
            })
        } else {
          navigate("/app/profile")
          return
        }
      })
      .catch(err => {
        setError(err.message)
      })
  }
  return (
    <Form>
      <Form.Field>
        <AuthFormLabel>EMAIL</AuthFormLabel>
        <Input
          type="email"
          name="email"
          value={email}
          id="email"
          placeholder="yourname@email.com"
          onChange={e => setEmail(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <AuthFormLabel>CURRENT PASSWORD</AuthFormLabel>
        <Form.Input
          type="password"
          name="currentPassword"
          value={currentPassword}
          id="currentPassword"
          onChange={e => setCurrentPassword(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <AuthFormLabel>NEW PASSWORD</AuthFormLabel>
        <Input
          type="password"
          name="newPassword"
          value={newPassword}
          id="newPassword"
          onChange={e => setNewPassword(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <AuthFormLabel>CONFIRM NEW PASSWORD</AuthFormLabel>
        <Input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          id="confirmPassword"
          onChange={e => setConfirmPassword(e.target.value)}
        />
      </Form.Field>
      <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
        <KpButton
          id="updateaccount-submit"
          color={theme.brand.colors.green}
          fullWidth="mobile tablet"
          buttonType={ButtonTypes.Primary}
          loading={loading}
          onClick={updatePassword}
        >
          {loading ? "Updating..." : "Change Password"}
        </KpButton>
      </Form.Field>
      {error && (
        <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
          <ErrorContainer>{error}</ErrorContainer>
        </Form.Field>
      )}
    </Form>
  )
}
export default ResetPassword
